<template>
  <div>
    <v-dialog v-model="dialog.dialogProgress" persistent width="300" eager>
      <v-card color="primary" dark>
        <v-card-text>
          {{progressMessage}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table
      v-model="vDataTable.selected"
      :headers="headers"
      :items="vDataTable.data"
      :search="vDataTable.search"
      show-select
      fixed-header
      :page.sync="vDataTable.options.page"
      :itemsPerPage.sync="vDataTable.options.itemsPerPage"
      :options.sync="vDataTable.options"
      :height="$vuetify.breakpoint.height - 123 - offsetAppBar"
      :footer-props="{
        itemsPerPageText: $t('itemsPerPageText'),
        itemsPerPageAllText: $t('itemsPerPageAllText'),
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
      item-key="ja_skey"
      class="fill-height">
      <template v-slot:header.data-table-select="{ on, props }">
        <tr>
          <td>
            <v-simple-checkbox :ripple="false" v-bind="props" v-on="on"></v-simple-checkbox>
          </td>
          <td v-if="vDataTable.selected.length > 0">
            {{vDataTable.selected.length}}
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{$t('MeeTeeMeeNgern1.JobApplicationTitle')}}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog.dialogAddEdit" max-width="1000px" eager>
            <template v-slot:activator="{ on, attrs }">
              <!-- <v-btn outlined color="primary" v-bind="attrs" v-on="on" style="text-transform: capitalize" @click="newItem()">
                <img src="../../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>{{$t('MeeTeeMeeNgern1.buttonnewArticles')}}
              </v-btn> -->
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{$t('MeeTeeMeeNgern1.JobApplicationTitle')}}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="3" class="mt-1 pt-1 pr-2">
                      <img :src="utils.imageProfile(vDataTable.editedItem.picture_base64)" id='thumb_image' height="150" weight="150"/>
                    </v-col>
                    <v-col cols="12" sm="12" md="9" class="mt-1 pt-1">
                      <v-row>
                        <v-col cols="12" sm="12" md="9" class="mt-1 pt-1">
                          <v-text-field
                            outlined dense hide-details
                            :label="$t('MeeTeeMeeNgern1.NameSurName')"
                            v-model="vDataTable.editedItem.fullname"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="mt-1 pt-1">
                          <v-text-field
                            outlined dense hide-details
                            :label="$t('MeeTeeMeeNgern1.birthDay')"
                            v-model="vDataTable.editedItem.birthday"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="mt-1 pt-1">
                          <v-textarea
                            readonly
                            :label="$t('MeeTeeMeeNgern1.ADDRESS')"
                            class="bg-editor"
                            dense
                            v-model="vDataTable.editedItem.addr"
                            hide-details
                            outlined
                            rows="2"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mt-1 pt-1">
                          <v-text-field
                            outlined dense hide-details
                            :label="$t('MeeTeeMeeNgern1.Tel')"
                            v-model="vDataTable.editedItem.phone"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mt-1 pt-1">
                          <v-text-field
                            outlined dense hide-details
                            :label="$t('MeeTeeMeeNgern1.email')"
                            v-model="vDataTable.editedItem.email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="mt-1 pt-1">
                          <v-textarea
                            readonly
                            :label="$t('MeeTeeMeeNgern1.eduHist')"
                            class="bg-editor"
                            dense
                            v-model="vDataTable.editedItem.edu_hist"
                            hide-details
                            outlined
                            rows="3"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="mt-1 pt-1">
                          <v-textarea
                            readonly
                            :label="$t('MeeTeeMeeNgern1.workHist')"
                            class="bg-editor"
                            dense
                            v-model="vDataTable.editedItem.work_hist"
                            hide-details
                            outlined
                            rows="3"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mt-1 pt-1">
                          <v-text-field
                            outlined dense hide-details
                            :label="$t('MeeTeeMeeNgern1.JobApplication')"
                            v-model="vDataTable.editedItem.position_desc"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mt-1 pt-1">
                          <v-text-field
                            outlined dense hide-details
                            :label="$t('MeeTeeMeeNgern1.DesiredSalary')"
                            v-model="vDataTable.editedItem.desired_salary"
                            readonly
                          ></v-text-field>
                        </v-col>
                       <!--  <v-col cols="5" class="mt-0 pa-0">
                          <v-checkbox outlined dense hide-details
                            readonly
                            false-value="N"
                            true-value="Y"
                            ref="checkBoxIHCSpecialStaining"
                            v-model="vDataTable.editedItem.policy_flag">
                            <template v-slot:label>
                              <div><span class='text--primary'>{{$t('MeeTeeMeeNgern1.policyFlag')}}</span></div>
                            </template>
                          </v-checkbox>
                        </v-col> -->
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn
                    :disabled="vDataTable.editedItem.resume_path != ''? false : true"
                    outlined
                    :color="vDataTable.editedItem.resume_path != ''? 'success' : 'gray'"
                    @click="Download(vDataTable.editedItem.resume_path, 'Resume_ID' + vDataTable.editedItem.ja_skey)">{{$t('MeeTeeMeeNgern1.downloadFileResume')}}</v-btn>
                  <v-btn outlined color="error" @click="close">{{$t('cancel')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            ref="dialogReceivedDateFromTo"
            v-model="dialog.dialogReceivedDateFromTo"
            :return-value.sync="criteria.ReceivedDateFromTo"
            persistent
            width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense
                hide-details
                outlined
                v-model="dateRangeText"
                label="Received Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"></v-text-field>
            </template>
            <v-date-picker
              v-model="criteria.ReceivedDateFromTo"
              range
              scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dialog.dialogReceivedDateFromTo = false">{{$t('cancel')}}</v-btn>
              <v-btn text color="primary" @click="$refs.dialogReceivedDateFromTo.save(criteria.ReceivedDateFromTo)">{{$t('ok')}}</v-btn>
            </v-date-picker>
          </v-dialog>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn outlined color="primary" style="text-transform: capitalize" @click="retrieveJobApplication(appName)">
            <img src="../../assets/SoftwareClinicIcon/Find.png" height="30" weight="30"/>{{$t('find')}}
          </v-btn>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn class="text-right" outlined color="primary" style="text-transform: capitalize" @click="exportExcel">
            <img src="../../assets/SoftwareClinicIcon/Office-excel-xls-icon.png" height="30" weight="30"/>export excel
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field outlined dense hide-details v-model="vDataTable.search" append-icon="mdi-magnify" :label="$t('search')"></v-text-field>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox :value="isSelected" :ripple="false" @input="select($event)" ></v-simple-checkbox>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="teal" small class="mr-2" v-bind="attrs" v-on="on" @click="editItem(item)">edit</v-icon>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="teal" small class="mr-2" v-bind="attrs" v-on="on" @click="Download(item.resume_path, 'Resume_ID' + item.ja_skey)">
              {{ item.resume_path != ''? 'mdi-attachment' : '' }}
            </v-icon>
          </template>
          <span>Download Resume</span>
        </v-tooltip>
      </template>
      <template v-slot:item.data-table-no="{ item, index }">
        {{((vDataTable.options.page - 1) * vDataTable.options.itemsPerPage) + index + 1}}
      </template>
      <template v-slot:no-data>
        <v-alert type="info" border="bottom" colored-border elevation="2">
          {{$t('noData')}}
        </v-alert>
      </template>
      <template v-slot:no-results>
        <v-alert type="warning">
          {{stringFormat($t('searchNotFound'),vDataTable.search)}}
        </v-alert>
      </template>
      <template v-slot:footer>
        <tr class="sticky-table-footer" style="position: absolute">
          <td>Selected({{vDataTable.selected.length}})s</td> 
        </tr>
      </template>
    </v-data-table> 
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import Base64Binary from 'base64-arraybuffer'
export default {
  components: {
  },

  props: ['parentComponent'],

  data () {
    return {
      utils: require('@/utils'),
      stringFormat: require('string-format'),
      appName: process.env.VUE_APP_DB_APP_NAME,
      rules: {
        winName: value => {
          const pattern = /^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/
          return pattern.test(value) || 'Invalid Window Name'
        },
      },
      dialog: {
        dialogProgress: false,
        dialogAddEdit: false,
        dialogLanguage: false,
        dialogReceivedDateFromTo: false,
      },
      criteria: {
        /* ReceivedDateFromTo: ['2020-10-01', '2020-10-31'], */
        ReceivedDateFromTo: [new Date(Date.now() - 1209600000).toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)],
      },
      vDataTable: {
        search: '',
        selected: [],
        data: [],
        editedIndex: -1,
        editedItem: {
          win_name: '',
          window_comment: ''
        },
        defaultItem: {
          win_name: '',
          window_comment: ''
        },
        editedLanguageIndex: -1,
        editedLanguageItem: {
        },
        defaultLanguageItem: {
        },
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
          // mustSort: true
        },
        expanded: [],
      },
      progressMessage: '',
      languages: [],
      languageEdit: true,
      idCardBase64: '',
    }
  },

  computed: {
    ...mapGetters(['getShowAppBar', 'getAppBarHeight']),
    formTitle () {
      return this.vDataTable.editedIndex === -1 ? this.$t('MeeTeeMeeNgern1.newSubscribe') : this.$t('MeeTeeMeeNgern1.newSubscribe')
    },
    formLanguageTitle () {
      return this.vDataTable.editedLanguageIndex === -1 ? this.$t('MeeTeeMeeNgern1.newSubscribeLanguage') : this.$t('MeeTeeMeeNgern1.editSubscribeLanguage')
    },
    headers () {
      return [
        /* { text: '#', align: 'center', value: 'data-table-no' }, */
        { text: 'ID', align: 'left', value: 'ja_skey', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('actions'), value: 'actions', sortable: false, class: 'text-xl-left body-1 black--text' },
        { text: this.$t('MeeTeeMeeNgern1.NameSurName'), value: 'fullname', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('MeeTeeMeeNgern1.birthDay'), value: 'birthday', align: 'left', class: 'text-xl-left body-1 black--text' },
        /* { text: this.$t('MeeTeeMeeNgern1.ADDRESS'), value: 'addr', align: 'left', class: 'text-xl-left body-1 black--text' }, */
        { text: this.$t('MeeTeeMeeNgern1.Tel'), value: 'phone', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('MeeTeeMeeNgern1.email'), value: 'email', align: 'left', class: 'text-xl-left body-1 black--text' },
        /* { text: this.$t('MeeTeeMeeNgern1.eduHist'), value: 'edu_hist', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('MeeTeeMeeNgern1.workHist'), value: 'work_hist', align: 'left', class: 'text-xl-left body-1 black--text' }, */
        { text: this.$t('MeeTeeMeeNgern1.JobApplication'), value: 'position_desc', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('MeeTeeMeeNgern1.DesiredSalary'), value: 'desired_salary', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('SubscribeMaint.policyFlag'), value: 'policy_flag', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('createDate'), value: 'create_date', align: 'left', class: 'text-xl-left body-1 black--text' },
      ]
    },
    subHeaders () {
      return [
        { text: this.$t('navWindow.windowName'), value: 'win_name' },
        { text: this.$t('createUser'), value: 'create_user_id' },
      ]
    },
    subHeadersLanguage () {
      return [
        { text: '', align: 'center', value: 'data-table-select' },
        { text: this.$t('actions'), value: 'actions', sortable: false },
        { text: this.$t('language'), value: 'language_comment' },
        { text: this.$t('MeeTeeMeeNgern1.name'), value: 'name', align: 'right' },
      ]
    },
    offsetAppBar () {
      // return this.getShowAppBar ? 0 : this.getAppBarHeight
      return this.getShowAppBar ? this.getAppBarHeight : 0
    },
    dateRangeText () {
      return this.criteria.ReceivedDateFromTo.join(' ~ ')
    },
  },

  watch: {
    'dialog.dialogAddEdit' (val) {
      val || this.close()
    }
  },

  async created () {
    await this.retrieveJobApplication(this.appName)
  },

  mounted () {
  },

  updated () {
  },

  methods: {
    selectLanguage (language) {
    },
    initialize () {
      this.vDataTable.selected = []
      this.vDataTable.editedIndex = -1
      this.vDataTable.editedItem = {
        win_name: '',
        window_comment: ''
      }
      this.vDataTable.defaultItem = {
        win_name: '',
        window_comment: ''
      }
    },
    exportExcel () {
      if (this.vDataTable.selected.length > 0) {
        this.axios.post(process.env.VUE_APP_API + '/work_with_us/ExportExcel', {data_json: this.vDataTable.selected}
        ).then(response => {
          if (response.data.isSuccess) {
            const url = window.URL.createObjectURL(new Blob([Base64Binary.decode(response.data.excel_base64)], {type: 'application/vnd.ms-excel; charset=utf-8'}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'export_excel.xlsx')
            document.body.appendChild(link)
            link.click()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      } else {
        var messageCustom = 'Please select detail.'
        this.$swal({type: 'error', html: messageCustom})
      }
    },
    newSubscribeLanguage (item) {
      this.vDataTable.editedItem = Object.assign({}, item)
      // this.dialogLanguage = true
      this.languageEdit = false
    },
    expandCollapseNode (expand) {
      if (expand) {
        this.vDataTable.expanded = this.vDataTable.data
      } else {
        this.vDataTable.expanded = []
      }
    },
    findCopyHeader (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      })
    },
    findNodeSelectedLanguage (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      }).selectedLanguage
    },
    async retrieveJobApplication (appName) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      if (this.criteria.ReceivedDateFromTo.length > 0) {
        this.dateSearchFrom = this.criteria.ReceivedDateFromTo[0]
        if (this.criteria.ReceivedDateFromTo.length > 1) {
          this.dateSearchTo = this.criteria.ReceivedDateFromTo[1]
        } else {
          this.dateSearchTo = new Date().toISOString().slice(0, 10)
        }
      }
      await this.axios.post(process.env.VUE_APP_API + '/work_with_us/retrieveJobApplication' + process.env.VUE_APP_DATABASE_TYPE, {
        date_from: this.dateSearchFrom,
        date_to: this.dateSearchTo,
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.data = response.data.data
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    async addSubscribe (name, email, tel, pictureBase64, activeFlag) {
      if (!tel) {
        tel = ''
      }
      if (!pictureBase64) {
        pictureBase64 = ''
      }
      await this.axios.post(process.env.VUE_APP_API + '/subscribe/addSubscribe' + process.env.VUE_APP_DATABASE_TYPE, {
        name: name,
        email: email,
        tel: tel,
        picture_base64: pictureBase64,
        active_flag: activeFlag
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.editedItem.skey = response.data.data.skey
            this.vDataTable.data.push(this.vDataTable.editedItem)
            this.retrieveJobApplication()
            this.$swal({type: 'success', title: response.data.reasonText})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async updateSubscribe (skey, name, email, tel, pictureBase64, activeFlag) {
      if (!tel) {
        tel = ''
      }
      if (!pictureBase64) {
        pictureBase64 = ''
      }
      await this.axios.post(process.env.VUE_APP_API + '/subscribe/updateSubscribe' + process.env.VUE_APP_DATABASE_TYPE, {
        skey: skey,
        name: name,
        email: email,
        tel: tel,
        picture_base64: pictureBase64,
        active_flag: activeFlag,
      })
        .then(response => {
          if (response.data.isSuccess) {
            Object.assign(this.vDataTable.data[this.vDataTable.editedIndex], this.vDataTable.editedItem)
            this.retrieveJobApplication()
            this.$swal({type: 'success', title: response.data.reasonText})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    checkDeleteselected () {
      if (this.vDataTable.selected.length > 0) {
        this.$swal({
          text: this.$t('MeeTeeMeeNgern1.confirmDeleteSelected'),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then((result) => {
          if (result) {
            this.deleteSelected()
          }
        })
      } else {
        this.$swal({type: 'warning', title: this.$t('MeeTeeMeeNgern1.messageDeleteNotSelected')})
      }
    },
    checkDeleteselectedLanguage (item) {
      if (this.findNodeSelectedLanguage(item.skey).length > 0) {
        this.$swal({
          text: this.$t('MeeTeeMeeNgern1.confirmSubscribeLanguageDeleteSelected'),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then((result) => {
          if (result) {
            this.deleteLanguageSelected(item)
          }
        })
      } else {
        this.$swal({type: 'warning', title: this.$t('MeeTeeMeeNgern1.messagedeleteSubscribeLanguageNotSelected')})
      }
    },
    async deleteSelected () {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')

      var del = []

      await this.utils.asyncForEach(this.vDataTable.selected, async function (x) {
        this.progressMessage = this.stringFormat(this.$t('MeeTeeMeeNgern1.progressMessageDelete'), x.name_name)
        var val = await this.deleteNode(x)

        if (val !== undefined) {
          del.push(val)
        }
      }.bind(this))
      for (const x in del) {
        this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(x), 1)
      }

      this.dialog.dialogProgress = false
    },
    async deleteLanguageSelected (item) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')

      var del = []

      await this.utils.asyncForEach(this.findNodeSelectedLanguage(item.skey), async function (x) {
        this.progressMessage = this.stringFormat(this.$t('MeeTeeMeeNgern1.progressMessageNodeLanguageDelete'), x.name)
        var val = await this.deleteLanguage(x)

        if (val !== undefined) {
          del.push(val)
        }
      }.bind(this))
      for (const x in del) {
        var selectedLanguage = this.findNodeSelectedLanguage(item.skey)
        selectedLanguage.splice(selectedLanguage.indexOf(x), 1)
      }

      this.dialog.dialogProgress = false
    },
    async deleteNode (item) {
      try {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        var skey = item.skey
        var valRet
        await this.axios.post(process.env.VUE_APP_API + '/subscribe/deleteSubscribe' + process.env.VUE_APP_DATABASE_TYPE, {
          skey: skey
        })
          .then(async response => {
            if (response.data.isSuccess) {
              if (this.vDataTable.data.indexOf(item) >= 0) {
                this.vDataTable.data.splice(this.vDataTable.data.indexOf(item), 1)
              }
              this.$swal({type: 'success', title: response.data.reasonText})
              valRet = item
            } else {
              await this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(async e => {
            await this.$swal({type: 'error', title: e.message})
          })
        return valRet
      } catch (e) {
        await this.$swal({type: 'error', title: e.message})
      } finally {
        this.dialog.dialogProgress = false
      }
    },
    newItem () {
      this.vDataTable.editedItem.active_flag = 'Y'
    },
    editItem (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.dialog.dialogAddEdit = true
    },
    async deleteItemConfirm (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.$swal({
        text: this.stringFormat(this.$t('MeeTeeMeeNgern1.confirmDelete'), item.name),
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then(async (result) => {
        if (result) {
          var val = await this.deleteNode(item)
          if (val !== undefined) {
            if (this.vDataTable.selected.indexOf(this.vDataTable.editedItem) >= 0) {
              this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(this.vDataTable.editedItem), 1)
            }
          }
          this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
          this.vDataTable.editedIndex = -1
        }
      })
    },
    close () {
      this.dialog.dialogAddEdit = false
      this.$nextTick(() => {
        this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
        this.vDataTable.editedIndex = -1
      })
    },
    async save () {
      if (this.vDataTable.editedIndex > -1) {
        await this.updateSubscribe(this.vDataTable.editedItem.skey,
          this.vDataTable.editedItem.name,
          this.vDataTable.editedItem.email,
          this.vDataTable.editedItem.tel,
          this.vDataTable.editedItem.picture_base64,
          this.vDataTable.editedItem.active_flag)
      } else {
        await this.addSubscribe(this.vDataTable.editedItem.name,
          this.vDataTable.editedItem.email,
          this.vDataTable.editedItem.tel,
          this.vDataTable.editedItem.picture_base64,
          this.vDataTable.editedItem.active_flag)
      }
      this.close()
    },
    closeLanguage () {
      this.dialog.dialogLanguage = false
      this.$nextTick(() => {
        this.vDataTable.editedLanguageItem = Object.assign({}, this.vDataTable.defaultLanguageItem)
        this.vDataTable.editedLanguageIndex = -1
      })
    },
    pickFileInsert (image) {
      this.$refs.imageInsert.click()
    },
    handleFileSelectInsert: function (event) {
      var input = event.target
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          var binaryData = e.target.result
          this.vDataTable.editedItem.tel = input.files[0].name
          this.vDataTable.editedItem.picture_base64 = window.btoa(binaryData)
        }
        reader.readAsBinaryString(input.files[0])
      }
    },
    Download (pathImage, filename) {
      this.axios.post(process.env.VUE_APP_API + '/work_with_us/getFileBase64', {
        path_image: pathImage
      }, {})
        .then(response => {
          if (response.data.isSuccess) {
            const url = window.URL.createObjectURL(new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/pdf; charset=utf-8'}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
  },
}
</script>
<style scoped>
.style-1 {
  color: rgb(215, 44, 44);
}
.style-2 {
  color: rgb(114,114,67);
}
.sticky-table-footer td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  border-top: thin solid rgba(0,0,0,.12);
}
</style>